.evaluation-progress-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.capturing-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.evaluation-capturing-text {
    font-size: 1rem;
    font-weight: 400;
}

.automation-icon {
    margin-right: .5rem;
}

.progress-bar-close-brn {
    background-color: transparent;
    border: none;
    color: #101828;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: .3rem;
}