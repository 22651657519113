.test-complete-screen {
    margin-top: 100px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.close-window-btn {
    font-size: .9rem !important;
    max-width: 460px;
}

.test-complete-img {
    width: 80px;
    margin-bottom: 1em;
}

.test-complete-header {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: bold;
}

.test-complete-text {
    color: #101828;
    max-width: 490px;
    margin: auto;
    line-height: 21px;
    margin-bottom: 1.5rem;
}

@media (min-width: 576px) {

    .close-window-btn {
        font-size: 1rem !important;
    }

}

@media (min-width: 992px) {
    .test-complete-header {
        font-size: 2.5rem;
    }

    .test-complete-img {
        width: 120px;
    }
}