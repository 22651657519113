/* Register.css */
.rbh-container {
    margin-top: 0;
    padding-top: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    margin: 0 !important;
    width: 100%;

}

.rbh-container .row {
    align-items: flex-start;
}

.rbh-container .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rbh-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0rem !important;
}

.rbh-centered-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center !important;
}

.inline-container {
    display: flex;
    align-items: center;
}

.inline-text {
    margin-right: 8px;
}

.inline-link {
    padding: 0;
}

.rbh-geturl-btn-container {
    display: flex;
    align-items: unset !important;
}

.rbh-back-dashboard-link.p-1 {
    padding: 0rem !important;

}
.rbh-back-dashboard-btn {
    margin-bottom: .5rem !important;
}

@media (min-width: 576px) {
    .rbh-container {
        max-width: 540px;
    }

}

@media (min-width: 768px) {
    .rbh-container {
        max-width: 720px;
    }

    .rbh-back-dashboard-btn {
        margin-bottom: 0rem !important;
    }

}

@media (min-width: 992px) {
    .rbh-container {
        max-width: 960px;
    }

    .rbh-form-container{
        width: 70% !important;
    }

    .rbh-form-container{
        width: 70% !important;
    }

    .home_screen_content_text{
        font-size: 33px !important; 
    }
}

@media (min-width: 1200px) {
    .rbh-container {
        max-width: 1140px;
    }
}


@media (min-width: 1400px) {
    .rbh-container {
        max-width: 1320px;
    }
}