:root {
    --sds-typography-body-font-family: 'Inter', sans-serif;
    --sds-typography-body-size-medium: 16px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.App-body {
    padding: 0rem;
    display: flex;
    justify-content: center;
}

.layout_content_wrapper {
    margin-top: 100px;
    width: 100%;
}

.layout_image_wrapper {
    display: none;
}

.all-fields-are-mandatory {
    color: #101828;
    margin-bottom: 1em;
    padding-left: .1em;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 100px auto;
}

.visibility-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 33;
    cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 992px) {
    .layout_content_wrapper {
        margin-top: 100px;
        flex-basis: 50%;
    }

    .layout_image_wrapper {
        display: flex;
        flex-basis: 50%;
        transform: translateY(-60px);
    }

    .layout_image_wrapper img {
        max-width: 105%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}