.toast-container-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
}

.toast-success {
  background-color: #e0ffe0 !important;
  color: #006400;
  font-weight: bold;
}

.toast-error {
  background-color: #ffe0e0 !important;
  color: #8b0000;
  font-weight: bold;
}

.toast-info {
  background-color: #e0f7ff !important;
  color: #004080;
  font-weight: bold;
}

.rbhc-form-terms {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem ;
}

.form-check-label {
  margin-left: .5rem;

}

.scrollable-modal-content {
  max-height: 300px;
  overflow-y: auto;
  padding: 20px;
  line-height: 1.6;
}


.scrollable-modal-content::-webkit-scrollbar {
  display: none;
}


.scrollable-modal-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable-modal-content h2 {
  font-size: 1.7rem;
}

.scrollable-modal-content h3 {
  font-size: 1.4rem;
}
