.download-report-col {
    justify-content: center;
}

.bhs-details-card {
    border: none;
    margin-top: 1.5rem;
}

@media (min-width: 768px) {
    .download-report-col {
        justify-content: start;
    }

    .bhs-details-card {
        margin-top: 0rem;
    }
}

.report-file-type-btn {
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    display: inline-flex;
    align-items: center;
    padding: 0 !important;
}

.report-file-type-btn svg {
    color: black !important;
    margin-bottom: .3em;
    margin-left: .3em;
}

.report-file-type-btn:hover,
.report-file-type-btn:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

@media (max-width: 767.98px) {

    /* Bootstrap's breakpoint for small screens */
    .custom-margin-sm {
        margin-top: 20px;
    }
}