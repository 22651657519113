.battery-chart-container {
    width: 100%;
    width: 250px !important;
    height: 100%;
    margin: auto;
    position: relative;
}


.battery-chart-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.2rem;
    font-weight: 400;
    color: #101828;
    height: fit-content;
}

.battery-chart-result {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1rem;
    font-weight: 800;
    color: #798597;
    height: fit-content;
}


@media screen and (min-width: 992px) {
    .battery-chart-text {
        top: 35%;
    }

    .battery-chart-result {
        font-size: 1.1rem;
    }

}