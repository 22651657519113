.standalone-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 505px;
    margin: auto;
}

.standalone-success-container h1 {
    font-size: 1.5rem;
}

.standalone-success-container p {
    font-size: 1rem;
}

.standalone-content {
    max-width: 900px;
    width: 100%;
    padding: 1rem;
}

.go-to-link {
    background-color: #1D78FF !important;
    color: #ffffff !important;
    margin: 0rem;
    width: 100%;
    border-radius: .5rem;
    padding: 0.7rem 0rem !important;
}

.go-to-link .icon-login {
    width: 24px;
    height: 24px;
    margin-right: .6rem;

}

.standalone-icon-wrapper {
    width: 160px;
    margin: auto;
}

.standalone-icon-wrapper img {
    max-width: 100%;
}

@media screen and (min-width:992px) {
    .standalone-icon-wrapper {
        width: 240px;
        margin: auto;
    }

    .standalone-success-container h1 {
        font-size: 40px;
    }
}