.home-screen-text-wrapper {
    margin-top: 7.5rem !important;
}

.home_screen_content_text {
    font-size: 23px;
    text-align: center !important;
}

@media (min-width: 992px) {
    .home_screen_content_text {
        text-align: unset !important;
    }

    .home-container {
        margin: 0 0 !important;
    }
}

.home-login-btn {
    background: transparent !important;
    color: #1D78FF !important;
}

.home-container {
    width: 100%;
    display: flex !important;
    flex-wrap: nowrap !important;
    margin: 0 20px !important;
}