.rbh-register-link {
    color: #1D78FF;
}

.login-title {
    margin-bottom: 0.5em;
}

.login-container {
    width: 100% !important;
    margin-top: 70px !important;
}

.login-screen-wrapper {
    width: 100%;
    display: flex !important;
    flex-wrap: nowrap !important;
    padding-left: 20px;
    padding-right: 20px;
}

.forget-password-wrapper {
    text-align: right;
}

.forget-password-wrapper a {
    color: #04AB9D !important;
    font-weight: 400;
}

.forgot-password-link {
    color: #10182899;
    opacity: 0.7;
    font-size: 15px;
}

@media (min-width: 992px) {
    .login-container {
        width: 90% !important;
    }
}