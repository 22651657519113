.test-details-card {
    border: none;
}

.test-details-header {
    background-color: #F9FAFB !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    margin-bottom: .8rem;
}

.test-details-header-text {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
}

.test-details-header-border {
    height: 1px;
    width: 100%;
    margin: auto;
    background-color: #E5E5E5;
}

.test-details-card-body {
    background-color: #F9FAFB !important;
    padding: '20px' 
}

.test-details-list-item {
    background-color: #F9FAFB !important;
    padding-bottom: 10px;
    border: none;
    padding-left: 0rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
}

.test-detail-info {
    color: #475467;
    font-size: 1rem;
}

.test-detail-value {
    color: #101828;
    font-size: 1rem;
    font-weight: 500;
}

@media screen and (min-width: 992px) {
    .test-details-list-item {
        flex-direction: row;
        justify-content: space-between;
    }
}