.form-control::placeholder {
    color: #475467;
    opacity: 50%;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1rem
}

.back-login-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.back-login-wrapper p {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: .5rem;
    color: #475467;
}

.back-login-icon {
    width: 7.4px;
    cursor: pointer;
}

.forget-password-header {
    font-size: 2.2rem;
    color: black;
    margin-bottom: 1rem;
}

.forget-password-text {
    font-size: .875rem;
}

.forget-password-text-wrapper {
    margin-bottom: 2rem;
}

@media (min-width: 992px) {
    .forget-password-text {
        font-size: 1rem;
    }
}