.app-header {
    background-color: #ffffff;
    padding: 15px 0;
}

.logo-container {
    display: flex;
    align-items: center;

}

.logo {
    height: 20px;
}

.smartcar-mode-dropdown {
    margin-bottom: 1em;
}

.separator {
    border: none;
    height: 1px;
    color: #EAECF0;
    background-color: #EAECF0;
    margin: 10px 0;
    opacity: 1;
}

.text-right {
    text-align: right;
}

.profile-dropdown.dropdown-toggle::after {
    display: none !important;
}

.dropdown-icon {
    margin-left: .3rem;
}

.dropdown-menue {
    margin-top: 1rem !important;
}


@media (min-width: 992px) {
    .app-header {
        padding: 30px 0;
    }

    .hide-separator-lg {
        display: none;
    }
}