.report-container {
    display: flex;
    justify-content: center;
    padding: 0rem !important;
    text-align: center;
}

.report-header {
    font-size: 1.5rem;
    font-weight: 'bold';
    font-weight: 700;
}

.test-details-car-icon {
    width: 18px;
    height: 16px;
    margin-bottom: .5rem;
}

.card-body {
    padding: 0rem !important;
}

.card-header {
    padding: 0rem !important;
}

.card {
    padding: 1rem !important;
    background-color: #F9FAFB !important;
    border-radius: .5rem;
}

.score-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .8rem;
}

.test-details-header-border {
    height: 1px;
    width: 100%;
    margin: auto;
    background-color: #E5E5E5;
}

.score-header {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
}

.battery-score-wrapper {
    background-color: #F9FAFB;
    padding: 20px;
    margin-top: 2rem;
}

.highlight-text {
    font-weight: 600;
}

.download-section {
    margin-top: 20px;
    text-align: center;
}

.download-section ul {
    padding-left: 0;
    list-style: none;
}

.download-section button {
    margin-top: 10px;
    padding: 10px 20px;
}

.download-section {
    margin-left: .6rem;
}

.report-score-header {
    margin-bottom: 3rem;
    font-weight: bold;
}

@media screen and (min-width: 992px) {
    .battery-score-wrapper {
        margin-top: 0rem;
    }
}