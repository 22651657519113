.table-container>:first-child {

    padding: 0rem !important;
}

.data-table-container {
    width: 100%;
    /* Ensure the container spans full width */
    overflow-x: auto;
    /* Enable horizontal scrolling */
}

.rdt_Table {
    table-layout: auto !important;
    /* Let the table determine column widths dynamically */
    width: auto !important;
    /* Remove 100% restriction */
}