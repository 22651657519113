.button-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.button-back,
.button-copy {
    padding: 15px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    line-height: normal;
    width: 250px;
}

.button-back {
    background-color: white;
    color: #4b4f58;
    border: 1px solid #e1e4e8;
}

.button-copy {
    background-color: #1d78ff;
    color: white;
    border: none;
    cursor: pointer;
}

.button-copy:hover {
    background-color: #1664c3;
}

.button-back:hover {
    background-color: #f0f4f8;
}

.click-open-test {
    overflow-wrap: anywhere !important;
    margin-bottom: 2rem !important;
    margin-top: 3rem !important;
}

.click-open-test>a {
    font-weight: 600;
    color: #101828;
    font-size: 25px;
}

.get-url-icon {
    max-width: 120px !important;
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
}