.welcome-screen {
    margin-top: 50px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.welcome-header {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: 900;
}

.welcome-text {
    color: #101828;
    font-size: 22px;
    font-weight: 450;
    line-height: 30px;
    margin: 20px auto;
    line-height: 1.6;
}

.continue-btn {
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    padding: 10px 0;
    width: 100%;
    max-width: 500px !important;
}

.icon-description {
    text-align: left;
    max-width: 350px;
}

@media (min-width: 992px) {
    .welcome-header {
        font-size: 2.5rem;
    }
}