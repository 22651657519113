.preliminary-title {
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    line-height: 48.41px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.preliminary-subtitle {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.preliminary-label {
    font-family: var(--sds-typography-body-font-family);
    font-size: var(--sds-typography-body-size-medium);
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

form .form-control.preliminary-input {
    background-color: #F9FAFB;
    border-color: #E5E7EB;
}

.btn-cancel {
    background-color: white !important;
    border: 1px solid #e1e4e8 !important;
    color: #344054 !important;
    font-weight: 500 !important;
    min-width: 60px;
}

.btn-start {
    background-color: #1D78FF !important;
    color: #fff !important;
    font-weight: 500 !important;
    width: 100%;
}

.btn-cancel:hover {
    filter: brightness(0.9);
}

.btn-start:hover {
    filter: brightness(0.9);
}

.preliminary-container {
    align-items: center;
    margin-left: 10px;
    max-width: 500px;
}