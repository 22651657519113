.battery-test-completion {
    margin-top: 64px !important;
    max-width: 600px;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 20px;
    padding-right: 20px;
}

.battery-test-completion-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
}

.battery-test-completion-text-first,
.battery-test-completion-text-second {
    color: '#101828';
    line-height: 21px;
    font-size: 1rem;
    text-align: center;
}

.battery-test-completion-text-first {
    margin-bottom: 2em;
}

.battery-test-completion-text-second {
    max-width: 88% !important;
    color: #475467;
}

.battery-test-completion-form {
    margin-top: 2.5rem;
}

.info_icon {
    margin-left: .4rem;
    margin-bottom: .4rem;
}