/* Ensure the modal dialog is perfectly centered but slightly higher than the middle */
.modal-custom .modal-dialog {
    max-width: 500px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding-top: 35vh;
}

/* Modal content styling */
.modal-custom .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    padding-left: 30px;
}

.modal-custom .modal-body {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
}

.modal-custom .modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.modal-custom .modal-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 45px;
    height: 45px;
    z-index: 10;
}

.modal-custom .btn-no {
    background-color: white !important;
    border: 1px solid #e1e4e8 !important;
    color: #344054 !important;
    font-weight: 500 !important;
    min-width: 60px;
}

.modal-custom .btn-yes {
    background-color: #1D78FF !important;
    color: #fff !important;
    font-weight: 500 !important;
    min-width: 60px;
}

.modal-custom .btn-no:hover {
    filter: brightness(0.9);
}

.modal-custom .btn-yes:hover {
    filter: brightness(0.9);
}

.modal-custom .modal-title {
    margin-left: 50px;
    margin-top: 3px;
    margin-bottom: 0px;
}

.modal-custom .modal-body-desc {
    margin-left: 50px;
    font-size: 16px;
    color: #555;
}