.form-group-custom{
    margin-bottom: 1rem;
}

.auth-form-label {
    margin-bottom: 0.75rem !important;
}

.auth-form-control {
    border-width: thin;
    min-height: 45px;
    border-radius: 8px !important;
    border-color: #CED4DA !important;
    background-color: #F9FAFB !important;
}