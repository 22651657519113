.register-terms-text {
    font-size: 15px;
    margin: 0 .25rem;
    user-select: none;
}

.register-terms-text span,
.register-terms-text a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
    text-decoration: none !important;
    cursor: pointer;
    margin: 0 .25rem;
}

.register-terms-text span:hover,
.register-terms-text a:hover {
    filter: brightness(0.6);
}

.register-container {
    margin-top: 70px !important;
}

.register-title {
    color: #101828;
}

.register-container {
    width: 100% !important;
}

.register-screen-wrapper {
    width: 100%;
    display: flex !important;
    flex-wrap: nowrap !important;

}

@media (min-width: 992px) {
    .register-container {
        width: 90% !important;
    }
}