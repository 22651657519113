.auth-nav-link {
    display: inline-block !important;
    margin-left: 6px;
    color: #1D78FF !important;
}

.auth-navigate-wrapper {
    margin-top: 24px;
    text-align: center;
}

.auth-navigate-wrapper p {
    color: #475467;
    user-select: none;
}