.battery-rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.battery-rating-icon {
    width: 10px;
    margin-right: .5rem;
    margin-bottom: .2em;
}

.battery-rating-text {
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
}

.battery-rating-text p {
    margin: 0;
}